import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { Link } from '../components/default/Link';
import Layout from '../components/site/Layout';
import SEO from '../components/site/SEO';

export default function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <Layout is404>
      <SEO title={t('404.title')}>
        <meta name="robots" content="noindex" />
      </SEO>
      <h1 className="text-center">404</h1>
      <p className="text-center">
        The page you were looking for wasn’t found.
        <br />
        <Link to="/">Go to the home page</Link>
      </p>
    </Layout>
  );
}

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
